<template>
  <div class="main-wrapper">
    <CommonModal />
    <Header />
    <main id="wrapper" class="main content account" role="main">
      <SideMenu />
      <Nuxt />
    </main>
    <Footer />
    <div ref="cookies" class="cookies-alert">
      <i class="bx bxs-cookie"></i>
      <p>
        {{ $t('common.cookies_accept') }}
      </p>
      <button type="button" @click="acceptCookies">
        {{ $t('common.agree') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

import Footer from '~/components/account/Footer'
import CommonModal from '~/components/modals/CommonModal'

const Web3 = require('web3')

export default {
  name: 'AccountLayoutComponent',
  components: {
    CommonModal,
    Footer,
    Header: hydrateWhenIdle(() => import('~/components/account/Header')),
    SideMenu: hydrateWhenIdle(() => import('~/components/account/SideMenu'))
  },
  data() {
    return {
      reloadingAccount: false
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('seos', [
      'contacts'
    ])
  },
  async beforeMount() {
    try {
      if (window !== undefined && window.ethereum !== undefined) {
        window.eth = new Web3(window.ethereum)
        const currentChain = this.$cookies.get('chainId')
        if (currentChain === undefined) {
          this.$cookies.set('chainId', parseInt(window.ethereum.networkVersion))
        }
        window.ethereum.on('connect', () => {
          this.$nuxt.$emit('walletReconnected')
        })
        window.ethereum.on('disconnect', () => {
          this.$nuxt.$emit('modalInfo', this.$t('common.wallet_disconnected'))
          this.$nuxt.$emit('walletDisconnected')
        })
        window.ethereum.on('accountsChanged', () => {
          this.$nuxt.$emit('modalInfo', this.$t('common.switched_accounts_text'), this.$t('common.switched_accounts'))
          this.$nuxt.$emit('walletReconnected')
        })
        window.ethereum.on('chainChanged', (newNetwork) => {
          this.$cookies.set('chainId', parseInt(newNetwork, 16))
          this.$nuxt.$emit('modalInfo', this.$t('common.switched_network_text'), this.$t('common.switched_network'))
          this.$nuxt.$emit('chainReconnected')
        })
      }
      if (this.contacts === null) {
        await this.fetchContacts()
      }
    } catch (err) {
      console.error(err)
    }
    try {
      if (this.contacts === null) {
        await this.fetchContacts()
      }
    } catch (err) {
      console.error(err)
    }
  },
  mounted() {
    this.$checkTheme()
    const userData = this.$cookies.get('auth.user')
    if (userData !== undefined) {
      this.checkUserAuth()
    }
    if (this.$cookies.get('cookiesAccept') === undefined && this.$refs.cookies !== undefined) {
      setTimeout(() => {
        this.$refs.cookies.classList.add('active')
      }, 4000)
    }
    document.querySelector('body').style.overflow = ''
  },
  methods: {
    ...mapActions('auth', [
      'checkAuth',
      'logout'
    ]),
    ...mapActions('seos', [
      'fetchContacts'
    ]),
    acceptCookies() {
      this.$refs.cookies.classList.remove('active')
      this.$cookies.set('cookiesAccept', 'true')
    },
    async checkUserAuth() {
      try {
        await this.checkAuth()
      } catch (err) {
        this.$cookies.remove('auth.user')
        this.$cookies.remove('auth.token')
      }
    },
    async logoutAction() {
      this.$clearCookies()
      await this.logout().then(() => {
        this.$cookies.remove('auth.user')
        this.$cookies.remove('auth.token')
        this.$router.push({
          path: this.localePath('/login')
        })
      })
    }
  }
}
</script>

<style>
  @import url('~/assets/css/account/style.css');
</style>
