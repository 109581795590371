<template>
  <div class="outter-app">
    <LeaveSite :url="leaveSiteUrl" />
    <div class="wrapper">
      <Header />
      <Nuxt />
      <Footer />
    </div>
    <div ref="cookies" class="cookies-alert">
      <i class="bx bxs-cookie"></i>
      <p>
        {{ $t('common.cookies_accept') }}
      </p>
      <button type="button" @click="acceptCookies">
        {{ $t('common.agree') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import {
  hydrateWhenIdle
} from 'vue-lazy-hydration'

export default {
  name: 'DefaultViewComponent',
  components: {
    Footer: hydrateWhenIdle(() => import('~/components/common/Footer')),
    Header: hydrateWhenIdle(() => import('~/components/common/Header')),
    LeaveSite: hydrateWhenIdle(() => import('~/components/modals/LeaveSite'))
  },
  data() {
    return {
      leaveSiteUrl: ''
    }
  },
  created() {
    this.$nuxt.$on('leaveSite', this.leaveSite)
  },
  mounted() {
    this.$checkCurrency()
    this.$checkTheme()
    const userData = this.$cookies.get('auth.user')
    if (userData !== undefined) {
      this.checkUserAuth()
    }
    if (this.$cookies.get('cookiesAccept') === undefined && this.$refs.cookies !== undefined) {
      setTimeout(() => {
        this.$refs.cookies.classList.add('active')
      }, 4000)
    }
    document.querySelector('body').classList.remove('scroll-none')
  },
  methods: {
    ...mapActions('auth', [
      'checkAuth'
    ]),
    ...mapMutations('modal', [
      'closeAll'
    ]),
    ...mapActions('modal', [
      'changeLeaveSiteState'
    ]),
    leaveSite(url) {
      this.leaveSiteUrl = url
      this.changeLeaveSiteState({
        state: true
      })
    },
    acceptCookies() {
      this.$refs.cookies.classList.remove('active')
      this.$cookies.set('cookiesAccept', 'true')
    },
    async checkUserAuth() {
      try {
        await this.checkAuth()
      } catch (err) {
        this.$cookies.remove('auth.user')
        this.$cookies.remove('auth.token')
      }
    }
  }
}
</script>
