var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-wrapper" },
    [
      _c("CommonModal"),
      _vm._v(" "),
      _c("Header"),
      _vm._v(" "),
      _c(
        "main",
        {
          staticClass: "main content account",
          attrs: { id: "wrapper", role: "main" },
        },
        [_c("SideMenu"), _vm._v(" "), _c("Nuxt")],
        1
      ),
      _vm._v(" "),
      _c("Footer"),
      _vm._v(" "),
      _c("div", { ref: "cookies", staticClass: "cookies-alert" }, [
        _c("i", { staticClass: "bx bxs-cookie" }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("common.cookies_accept")) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "button",
          { attrs: { type: "button" }, on: { click: _vm.acceptCookies } },
          [_vm._v("\n      " + _vm._s(_vm.$t("common.agree")) + "\n    ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }