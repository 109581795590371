var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "main info-section" }, [
    _c("div", { staticClass: "container-md services-area" }, [
      _c("div", { staticClass: "error-area" }, [
        _c("div", { staticClass: "error-message" }, [
          _c("div", { staticClass: "citation-section" }, [
            _c("div", { staticClass: "error-text" }, [
              _c("h2", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("errors.smth_happen")) +
                    "...\n            "
                ),
              ]),
              _vm._v(" "),
              _c("h3", [
                _vm._v(
                  "\n              " + _vm._s(_vm.errorCode) + "\n            "
                ),
              ]),
              _vm._v(" "),
              _vm.errorCode === 404
                ? _c("h1", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("errors.page_wasnt_found")) +
                        "\n            "
                    ),
                  ])
                : _vm.errorCode === 500
                ? _c("h1", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("errors.page_wasnt_loaded")) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "error-image" },
              [
                _c("nuxt-img", {
                  attrs: {
                    src: "/svg/" + _vm.errorCode + ".svg",
                    alt: _vm.$t("errors.not_found_alt"),
                    title: _vm.$t("errors.not_found_title"),
                    placeholder: "",
                    lazy: true,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "error-description" }, [
            _vm.errorCode === 404
              ? _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("errors.not_found_text")) +
                      "\n          "
                  ),
                ])
              : _vm.errorCode === 500
              ? _c("p", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("errors.not_loaded_text")) +
                      "\n          "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "footer",
          { staticClass: "error-footer" },
          [
            _c(
              "nuxt-link",
              {
                staticClass: "footer-link",
                attrs: { to: _vm.localePath("/") },
              },
              [
                _c("i", { staticClass: "bx bxs-home" }),
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("common.main")) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("i", { staticClass: "bx bx-minus mx-3" }),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "footer-link",
                attrs: { to: _vm.localePath("/support") },
              },
              [
                _c("i", { staticClass: "bx bx-support" }),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("common.support")) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("i", { staticClass: "bx bx-minus mx-3" }),
            _vm._v(" "),
            _vm.user === null
              ? _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-link",
                    attrs: { to: _vm.localePath("/login") },
                  },
                  [
                    _c("i", { staticClass: "bx bx-log-in-circle" }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("common.sign_in")) +
                        "\n        "
                    ),
                  ]
                )
              : _c(
                  "nuxt-link",
                  {
                    staticClass: "footer-link",
                    attrs: { to: _vm.localePath("/account") },
                  },
                  [
                    _c("i", { staticClass: "bx bxs-dashboard" }),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("common.dashboard")) +
                        "\n        "
                    ),
                  ]
                ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }