<template>
  <section class="main info-section">
    <div class="container-md services-area">
      <div class="error-area">
        <div class="error-message">
          <div class="citation-section">
            <div class="error-text">
              <h2>
                {{ $t('errors.smth_happen') }}...
              </h2>
              <h3>
                {{ errorCode }}
              </h3>
              <h1 v-if="errorCode === 404">
                {{ $t('errors.page_wasnt_found') }}
              </h1>
              <h1 v-else-if="errorCode === 500">
                {{ $t('errors.page_wasnt_loaded') }}
              </h1>
            </div>
            <div class="error-image">
              <nuxt-img
                :src="'/svg/' + errorCode + '.svg'"
                :alt="$t('errors.not_found_alt')"
                :title="$t('errors.not_found_title')"
                placeholder
                :lazy="true"
              />
            </div>
          </div>
          <div class="error-description">
            <p v-if="errorCode === 404">
              {{ $t('errors.not_found_text') }}
            </p>
            <p v-else-if="errorCode === 500">
              {{ $t('errors.not_loaded_text') }}
            </p>
          </div>
        </div>
        <footer class="error-footer">
          <nuxt-link class="footer-link" :to="localePath('/')">
            <i class="bx bxs-home"></i>
            {{ $t('common.main') }}
          </nuxt-link>
          <i class="bx bx-minus mx-3"></i>
          <nuxt-link class="footer-link" :to="localePath('/support')">
            <i class="bx bx-support"></i>
            {{ $t('common.support') }}
          </nuxt-link>
          <i class="bx bx-minus mx-3"></i>
          <nuxt-link v-if="user === null" class="footer-link" :to="localePath('/login')">
            <i class="bx bx-log-in-circle"></i>
            {{ $t('common.sign_in') }}
          </nuxt-link>
          <nuxt-link v-else class="footer-link" :to="localePath('/account')">
            <i class="bx bxs-dashboard"></i>
            {{ $t('common.dashboard') }}
          </nuxt-link>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ErrorLayoutComponent',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pageNotFound: this.$t('common.page_not_found'),
      otherError: this.$t('common.other_error')
    }
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    errorCode() {
      return this.error.statusCode
    }
  },
  mounted() {
    if (this.$config.nodeEnv !== 'production') {
      console.log(this.error)
    }
  },
  methods: {
    loadPage() {
      if (this.$router.path === '/') {
        location.reload()
      }
      this.$router.push({
        path: this.localePath('/')
      })
    }
  }
}
</script>

  <style scoped>
    @import url('~/assets/css/404.css');
  </style>
